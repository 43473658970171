import React from "react";

const HvtRnC = () => {
  return (
    <section className="bg-light mt-5 mb-5 p-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h2 className="fw-bold fs-1">
              Refund &<span className="text main-hover"> Cancellation </span>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 text-justify">
            
              Refund and Cancellation Policy of Holy Vrindavan Tour- Payment
              Terms: After confirmation of any Package request:
              25% of tour package cost required immediately to book all the
              respective services. 15 Days prior to arrival date: 50% of tour
              package cost required. 07 Days prior to arrival date: 100% of tour
              package cost required. All payments must be notified by an e-mail
              format only with attached transaction details. Payment terms are
              subject to change at the time of booking as per Hotel Policy and
              High Season. We will not entertain any credit facility.
             <h5> Cancellation Policy:</h5>
              All cancellations must be made in e-mail
              format only. Any cancellation at 45 Days prior to arrival date –
              No charge Any cancellation between 45 Days – 30 Days prior to
              arrival date: 25% of tour fare charge Any cancellation between 30
              Days – 15 Days prior to arrival date: 50% of tour fare charge Any
              cancellation between 15 Days – 10 Days prior to arrival date: 75%
              of tour fare charge Any cancellation less than 10 Days: 100% of
              tour fare charge. Once the package is booked & confirmed North
              Sikkim part is Non Cancelable or Non Refundable. Refunds (if
              applicable) If your refund are approved, then your refund will be
              processed, and a credit will automatically be applied to your
              credit card or original method of payment, within a certain amount
              of days. Late or missing refunds (if applicable) If you haven’t
              received a refund yet, first check your bank account again. Then
              contact your credit card company, it may take some time before
              your refund is officially posted. Next contact your bank. There is
              often some processing time before a refund is posted. If you’ve
              done all of this and you still have not received your refund yet,
              please contact us at holyvrindavantour@gmail.com. Sale Services
              (if applicable) Only regular priced Services may be refunded,
              unfortunately sale Services cannot be refunded. Exchanges (if
              applicable) We only replace Services if they are defective or
              damaged. If you need to exchange it for the same item, send us an
              email at <a href="">holyvrindavantour@gmail.</a>com and send your request to:Near
              Anand Krushna Van hotel, Madhuvan Colony, Vrindavan (Mathura) -
              281121 We may change our refund and cancellation policy anytime.
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default HvtRnC;
