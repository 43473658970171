import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
const GetCustomTour = () => {
    const [viewData, setViewData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fetchData, setFetchData] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios
            .get("https://hvt-backend.vercel.app/user/get-user")
            .then((res) => {
                setViewData(res?.data?.result || []);
                setLoading(false);
                setFetchData(false); // Reset the fetchData flag
            })
            .catch((err) => {
                setError("Failed to fetch data. Please try again later.");
                setLoading(false);
            });
    }, [fetchData]);

    const filteredUsers = [...new Set(viewData.filter((user) => user.locations).map((user) => user._id))].map((id) => {
        return viewData.find((user) => user._id === id);
    });

    const handleDelete = (id) => {
        if (window.confirm(`Are you sure you want to delete user ${id}?`)) {
            axios
                .delete(`https://hvt-backend.vercel.app/user/delete/${id}`)
                .then((res) => {
                    setFetchData(true); // Trigger a re-fetch of data
                })
                .catch((err) => {
                    setError("Failed to delete user. Please try again later.");
                });
        }
    };

    return (
        <div className='container'>
            <h1>Custom Tour Enquiries</h1>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <ul className='my-3'>
                    {filteredUsers.map((user, index) => (
                        <li key={index}>
                            <div className="user-box">
                                <h2>{user.user.name}</h2>
                                <p>Email: {user.user.email}</p>
                                <p>Phone: {user.user.phone}</p>
                                <p>Locations:</p>
                                <ul>
                                    {user.locations.map((location, index) => (
                                        <li key={index}>{location}</li>
                                    ))}
                                </ul>
                                <Button className='my-3' variant="outline-danger"
                                    size="sm" onClick={() => handleDelete(user._id)}>Delete</Button>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default GetCustomTour;