import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../pagesCss/Dashboard.css";

const Dashboard = () => {
  const [userData, setUser] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const users = await axios.get("https://hvt-backend.vercel.app/user/get-user");
      setUser(users?.data?.result);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="container">
      <div className="row m-4 gy-4">
        <div className="col col-xl-4">
          <div className="card p-4 border-primary bg-light">
            <FontAwesomeIcon icon={faUsers} size="3x" className="text-primary" />
            <div className="mt-4 text-center">
              <p className="fw-bold">
                Total users: <span>{userData.length}</span>
              </p>
            </div>
            <Link
              to="/dashboard"  
              className="btn btn-sm btn-outline-primary"
            >
              Go to Users
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
