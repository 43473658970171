import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';


import './App.css';
import HvtFooter from './pages/HvtFooter';
import HvtFloatingbtn from './components/HvtFloatingbtn';
import InquiryForm from './components/InquiryForm';
import ScrollToTop from './pages/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import AppRoute from './AppRoute'; // New component for routes

function App() {
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 8000);

    return () => clearTimeout(timer);
  }, []);

  const closeForm = () => {
    setShowForm(false);
  };

  return (
    <>
      <BrowserRouter>
        {showForm && <InquiryForm onClose={closeForm} />}
        <ToastContainer />
        <ScrollToTop />
        <AppRoute /> {/* This is the new routes component */}
        <HvtFloatingbtn />
        <HvtFooter />
      </BrowserRouter>
    </>
  );
}

export default App;
