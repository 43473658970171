import React from 'react';
import { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HvtCarousel from './pages/HvtCarousel';
import HvtContactForm from './pages/HvtContactForm';
import HvtReview from './pages/HvtReview';
import HvtWwr from './pages/HvtWwr';
import Hvttourcard from './pages/Hvttourcard';
import TourDetails from './components/TourDetails';
import HvtTnC from './pages/HvtTnC';
import HvtPrivacyP from './pages/HvtPrivacyP';
import HvtRnC from './pages/HvtRnC';
import HvtReservationP from './pages/HvtReservationP';
import Gallery from './pages/Gallery';
import AboutFounder from './pages/AboutFounder';
import WhyCUs from './pages/WhyCUs';
import FAQ from './pages/FAQ';
import CustomizedTour from './pages/CustomizedTour';
import Payment from './pages/Payment';
import Login from './pages/Login';
import MainDashboard from './pages/MainDashboard';
import { HvtHeader } from './pages/HvtHeader';
import Dashboard from './pages/Dashboard';
import Getcustomtour from './pages/Getcustomtour';
import ProtectedRoute from './auths/ProtectedRoute';
import ForgetPassword from './pages/ForgetPassword';
import { AuthProvider } from './pages/AuthContext';
const AppRoutes = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      {/* Hide HvtHeader on any /dashboard route */}
      <AuthProvider>

      {!location.pathname.startsWith('/dashboard') && <HvtHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />}

      <Routes>
        <Route path="/" element={
          <>
           {/* <HvtHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} /> */}
           <HvtCarousel isMenuOpen={isMenuOpen} />
            <HvtWwr />
            <Hvttourcard />
            <WhyCUs />
            <AboutFounder />
            <HvtReview />
            <FAQ />
            <HvtContactForm />
          </>
        } />
        <Route path="/about" element={<HvtWwr />} />
        <Route path="/destinations" element={<Hvttourcard />} />
        <Route path="/contact" element={<HvtContactForm />} />
        <Route path="/tour/:id" element={<TourDetails />} />
        <Route path="/terms-conditions" element={<HvtTnC />} />
        <Route path="/privacy-policy" element={<HvtPrivacyP />} />
        <Route path="/refund-policy" element={<HvtRnC />} />
        <Route path="/reservation-policy" element={<HvtReservationP />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/customized-tour" element={<CustomizedTour />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <MainDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/users"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/get-custom-tour"
          element={
            <ProtectedRoute>
              <Getcustomtour />
            </ProtectedRoute>
          }
        />
      </Routes>
      </AuthProvider>

    </>
  );
};

export default AppRoutes;
