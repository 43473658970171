// src/components/ProtectedRoute.js

import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "./auth"; // Import the isAuthenticated utility

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // If the user is authenticated, render the children components (protected routes)
  return children;
};

export default ProtectedRoute;
