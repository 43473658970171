import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState({});
const navigate=useNavigate()
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const login = (token, userData) => {
    setToken(token);
    setUserData(userData);
    localStorage.setItem('token', token);
  };

  const logout = () => {
    setToken(null);
    setUserData({});
    localStorage.removeItem('token');
    navigate("/")

  };
console.log(".............",userData);
  return (
    <AuthContext.Provider value={{ token, userData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };