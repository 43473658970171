import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../pagesCss/Login.css";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "./AuthContext"
import { useState, useContext } from "react";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext)
  // State for button state management
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const [isError, setIsError] = useState(false);

  const signup = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (initialValues) => {


      const url = "https://hvt-backend.vercel.app";
      const userApiUrl = `${url}/admin/login`;

      axios.post(userApiUrl, initialValues)
        .then((res) => {
          // console.log(res);
          if (res) {
            toast.success("Admin login successful");
            const token = res.data.token;
            const userData = res.data.result
            login(token, userData)
            setTimeout(() => {
              navigate("/dashboard", { replace: true });
            }, 1500);
          }
        })
        .catch((error) => {

          toast.error(error?.response?.data?.message || "An error occurred");
        });

    },
  });

  return (
    <>
      <div className="login-page vh-100 d-flex justify-content-center align-items-center">
        <div className="login-container rounded-2 p-4 bg-transparent">
          <div className="mb-4 text-center text-primary">
            <FontAwesomeIcon icon={faUser} size="5x" />
          </div>

          <form onSubmit={signup.handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control mt-1 bg-light"
                id="email"
                name="email"
                value={signup.values.email}
                onChange={signup.handleChange}
                required
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                className="form-control mt-1 bg-light"
                id="password"
                name="password"
                value={signup.values.password}
                onChange={signup.handleChange}
                required
              />
            </div>
            <button
              className={`btn w-100 `}
              type="submit"
            // disabled={isSubmitting}
            >Submit

            </button>
            <hr />
            <Link style={{ textDecoration: 'none', fontSize: "16px" }} to="/forget-password">
              Forget-password
            </Link>
          </form>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Login;
