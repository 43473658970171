import React from 'react';
import "../pagesCss/Payment.css";
const upi = require("../payment.jpg");

const Payment = () => {
    return (
        <div className="container mt-5 pt-5">
            <div className="row">
                <div className="col-12 col-md-4 mb-4 Name">
                    <h3 className='text-center pt-3'>NAME</h3>
                    <hr />
                    <h2><strong className='text-decoration-underline'>Deepak Sagar</strong></h2>
                    <br />
                    <div className='text-justify'>
                    <p>Trustworthy bank details for your peace of mind:</p>
                    <p><strong>Account Name:</strong> Deepak Sagar</p>
                    <p><strong>Bank:</strong> Bank Of Baroda</p>
                    <p><strong>Account Number:</strong> 85770100002768</p>
                    <p><strong>IFSC:</strong> BARB0DBVRIN</p>
                    <p>Our banking transactions are fully encrypted and verified to ensure maximum security and confidentiality. Your trust is our priority, and we guarantee a safe and secure experience.</p>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-4 upi">
                    <h3 className='text-center pt-3'>UPI</h3>
                    <hr />
                    <img src={upi} alt='UPI' className="img-fluid"/>
                </div>
                <div className="col-12 col-md-4 mb-4 det">
                    <h3 className='text-center pt-3'>Bank Details</h3>
                    <hr />
                    <div className='text-justify'>
                    <p className='fs-5'><strong>Bank Name:</strong> Bank Of Baroda</p>
                    <p className='fs-5'><strong>Account Holder:</strong> Deepak Sagar</p>
                    <p className='fs-5'><strong>Branch:</strong> Vrindavan</p>
                    <p className='fs-5'><strong>Account Number:</strong> 85770100002768</p>
                    <p className='fs-5'><strong>IFSC Code:</strong> BARB0DBVRIN</p>
                    <p className='fs-5'><strong>Pin Code:</strong> 281121</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payment;
