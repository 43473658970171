import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate()
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let response, data;

            if (!otpSent) {
                response = await fetch('https://hvtbackend.vercel.app/admin/request-password-reset', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email }),
                });
            } else if (!otpVerified) {
                response = await fetch('https://hvtbackend.vercel.app/admin/otp-verification', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, otp }),
                });
                console.log(response);
            } else {
                if (newPassword !== confirmPassword) {
                    setError('Passwords do not match');
                    return;
                }
                response = await fetch('https://hvtbackend.vercel.app/admin/reset-password', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, otp, newPassword }),
                });
            }

            // Check the content type of the response
            const contentType = response.headers.get('content-type');

            // If response is JSON, parse it
            if (contentType && contentType.includes('application/json')) {
                data = await response.json();
            } else {
                // If response is not JSON, parse it as text
                data = await response.text();
            }

            if (response.ok) {
                if (!otpSent) {
                    setOtpSent(true);
                    setError(null);
                    setSuccess('OTP sent to your email.');
                } else if (!otpVerified) {
                    setOtpVerified(true);
                    setError(null);
                    setSuccess('OTP verified. Please enter a new password.');
                } else {
                    setSuccess('Password reset successfully!');
                    setNewPassword('');
                    setEmail('');
                    setOtp('');
                    setConfirmPassword('');
                    setError(null);
                    toast.success("password changed successfully")
                    setTimeout(() => {
                        navigate('/login')
                    }, 1500);
                }
            } else {
                setError(`Error: ${data.message || data || 'An error occurred. Please try again.'}`);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setError('A network error occurred. Please try again later.');
        }
    };


    return (
        <div className='container my-3'>

            <form onSubmit={handleSubmit} className='bg-light row col-6 col-sm-6 col-md-5 mx-auto'>
                <h1 className='fs-5'>Forget Password</h1>
                <label col-sm-12>
                    Enter Your E-mail
                    <input
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                        disabled={otpSent}
                        className="form-control"
                        placeholder='xyz@email.com'
                    // style={{width:"80%"}}
                    />

                </label>


                {otpSent && !otpVerified && (
                    <label>
                        OTP:
                        <input
                            type="number"
                            value={otp}
                            onChange={(event) => setOtp(event.target.value)}
                            required
                            className="form-control"
                        />
                    </label>
                )}
                {otpVerified && (
                    <div>
                        <label>
                            New Password:
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(event) => setNewPassword(event.target.value)}
                                required
                                className="form-control "
                            />
                        </label>
                        <label>
                            Confirm Password:
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                required
                                className="form-control "
                            />
                        </label>
                    </div>
                )}



                <button type="submit" className='btn btn-dark my-2 mx-auto' style={{ width: "50%" }}>
                    {!otpSent ? 'Send OTP' : otpVerified ? 'Reset Password' : 'Verify OTP'}
                </button>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                {success && <div style={{ color: 'green' }}>{success}</div>}
            </form>
            <ToastContainer />
        </div>
    );
};

export default ForgetPassword;
