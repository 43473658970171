import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faRightFromBracket,
  faTachographDigital,
  faUsers,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Table, Button, Spinner, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from "./AuthContext";

const MainDashboard = ({ useremail }) => {
  const [viewData, setViewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fetchData, setFetchData] = useState(false);
  const navigate = useNavigate();
  const { token, logout } = useContext(AuthContext);
  const alertShown = useRef(false); // Use useRef for alert tracking

  useEffect(() => {
    if (!token) {
      if (!alertShown.current) {
        alert("You are not Authorized to access this Route ");
        alertShown.current = true; // Update ref to indicate alert has been shown
      }
      navigate("/");
      return; // Exit early if no token
    }

    setLoading(true);
    axios
      .get("https://hvt-backend.vercel.app/user/get-user")
      .then((res) => {
        setViewData(res?.data?.result || []);
        setLoading(false);
        setFetchData(false); // Reset the fetchData flag
      })
      .catch((err) => {
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      });
  }, [token, navigate]);

  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete the data?");
      if (confirmDelete) {
        await axios.delete(`https://hvt-backend.vercel.app/user/delete/${id}`);
        alert('User data deleted successfully.');
        setViewData(viewData.filter((user) => user._id !== id));
      }
    } catch (error) {
      console.log(error);
      alert('Failed to delete user data.');
    }
  };

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#home">Holy Vrindavan Tour</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/dashboard">
                <FontAwesomeIcon icon={faTachographDigital} className="me-2" />
                Dashboard
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/users">
                <FontAwesomeIcon icon={faUsers} className="me-2" />
                Users
              </Nav.Link>
              <Nav.Link as={Link} to="/dashboard/get-custom-tour">
                <FontAwesomeIcon icon={faComments} className="me-2" />
                Customized Tour
              </Nav.Link>
              <Nav.Link onClick={logout}>
                <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />
                Logout
              </Nav.Link>
            </Nav>
            <Navbar.Text className="ml-auto">
              <FontAwesomeIcon icon={faCircleUser} className="me-2" />
              Welcome Back, {useremail}
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="mt-5">
        <h3>The User List is here!</h3>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Number of Adults</th>
                <th>Category</th>
                <th>Mobile Number</th>
                <th>Date of Tour</th>
                <th>Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {viewData
                .filter((e) => !e.locations) // Filter out data that contains an address
                .map((e) => (
                  <tr key={e._id}>
                    <td>{e.Name}</td>
                    <td>{e.Email}</td>
                    <td>{e.Numberofadults}</td>
                    <td>{e.category}</td>
                    <td>{e.mobNumber}</td>
                    <td>{e.Date}</td>
                    <td>{e.createdAt}</td>
                    <td>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDelete(e._id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </Container>
    </div>
  );
};

export default MainDashboard;
